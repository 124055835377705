import { openConfirmModal } from '@mantine/modals';
import { Button, ButtonVariant } from '@mantine/core';

interface ConfigureTitleProps {
  children?: React.ReactNode;
  variant?: ButtonVariant;
}

export const ConfigureTitle = (props: ConfigureTitleProps) => {
  const openModal = () =>
    openConfirmModal({
      title: 'Configuracion de titulo en escena',
      children: <></>,
      labels: { confirm: 'Guardar', cancel: 'Cancelar' },
      onCancel: () => console.log('Cancelar'),
      onConfirm: () => console.log('Guardar'),
    });

  return (
    <Button pl={10} pr={10} variant={props?.variant} onClick={openModal}>
      {props?.children}
    </Button>
  );
};
