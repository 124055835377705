import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useMemo, useState } from 'react';

import { auth } from '../../config/firebase';
import { AuthContext, UserData } from './AuthContext';

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider(props: AuthProviderProps) {
  const [user, setUser] = useState<UserData | null>(null);

  const getCurrentUser = (): Promise<UserData> => {
    return new Promise(async (resolve, reject) => {
      if (!user) {
        let userFlag = await onAuthStateChanged(auth, (user: any) => {
          if (user) {
            setUser(user);
          } else {
            setUser(null);
          }
        });

        userFlag();
        resolve(user);
      } else {
        return resolve(user);
      }
    });
  };

  useEffect(() => {
    async function callUserFetch() {
      localStorage.setItem('landing-url', window.location.pathname);
      await getCurrentUser();
    }
    callUserFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      user,
      setUser,
      getCurrentUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
