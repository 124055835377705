import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config';

export const loadSectionContent = async (sectionId: string) => {
  return new Promise(async (resolve, reject) => {
    let auxSectionContent: any[] = [];
    const q = query(
      collection(db, 'files'),
      where('sectionId', '==', sectionId)
    );
    const querySnapshot = await getDocs(q);

    for (let i = 0; i < querySnapshot.docs.length; i++) {
      let auxFile = querySnapshot.docs[i].data();
      auxFile.id = querySnapshot.docs[i].id;

      auxSectionContent.push(auxFile);
    }
    resolve([...auxSectionContent]);
  });
};

export const getSectionById = (id: string, sections: any) => {
  return (sections?.find((section: any) => section.id === id)).content;
};
