import { deleteDoc, doc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../config/firebase";

export async function removeImageFromGallery(
  path: string,
  docId: string
): Promise<void> {
  const imageRef = ref(storage, path);
  await deleteDoc(doc(db, "files", docId));

  return deleteObject(imageRef);
}
