import { Button } from "@mantine/core";
import { FaChevronLeft } from "react-icons/fa";
import { To, useNavigate } from "react-router-dom";

export function Back(props: { to?: To }) {
  const navigate = useNavigate();
  return (
    <Button
      variant="subtle"
      color={"dark"}
      onClick={() => navigate(props?.to ? props?.to : (-1 as To))}
    >
      <FaChevronLeft />
    </Button>
  );
}
