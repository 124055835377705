import { AppShell, Divider, List, Loader, NavLink } from '@mantine/core';
import { useContext, useState } from 'react';
import { FaDoorOpen, FaPlus, FaPlusSquare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/logout';
import { HeaderWithTitle } from '../../components';
import { AuthContext } from '../../context';

export function Home() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setUser } = useContext(AuthContext);

  const callLogout = async () => {
    setIsLoading(true);
    try {
      await logout();
      setUser(null);
      navigate('/login');
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <AppShell header={<HeaderWithTitle title="Slidenotes" />}>
      <List
        sx={(theme) => ({
          padding: '0.4rem',
          width: '100%',
          maxWidth: 360,
          '& li': { listStyle: 'none' },
        })}
        aria-labelledby="nested-list-subheader"
      >
        <List.Item>
          <NavLink
            onClick={() => navigate('/s')}
            label="Crear sesión"
            icon={<FaPlusSquare />}
            variant="subtle"
            color={'dark'}
            active
          />
        </List.Item>

        <List.Item>
          <NavLink
            onClick={() => navigate('/a')}
            label="Abrir..."
            icon={<FaPlus />}
            variant="subtle"
            color={'dark'}
            active
          />
        </List.Item>
        <Divider />
        <List.Item>
          {isLoading ? (
            <Loader color={'dark'} size="sm" />
          ) : (
            <NavLink
              onClick={() => callLogout()}
              label="Salir"
              icon={<FaDoorOpen />}
              variant="subtle"
              color={'dark'}
              active
            />
          )}
        </List.Item>
      </List>
    </AppShell>
  );
}
