import { useEffect, useState } from 'react';
import {
  AppShell,
  Box,
  Button,
  Tabs,
  Text,
  Input,
  Divider,
  Loader,
  Card,
  Group,
  Tooltip,
  ActionIcon,
} from '@mantine/core';

import { truncate } from 'lodash';

import {
  ConfigureTitle,
  Gallery,
  InputLabel,
  ViewLoader,
} from '../../components';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Dropzone from 'react-dropzone';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, storage } from '../../config';
import { useParams, useNavigate } from 'react-router-dom';
import { SessionHeader } from './SessionHeader';
import {
  handleViewDisplay,
  UNIXToReadableDate,
  setTitleDisplay,
  handleViewText,
} from '../../util';

import { showNotification } from '@mantine/notifications';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ElementMapType, SessionLayout } from '../../Layout';

import imageCompression from 'browser-image-compression';
import {
  RiText,
  RiArrowRightUpLine,
  RiExternalLinkLine,
  RiAddBoxFill,
  RiCloseLine,
  RiSettings3Fill,
  RiDeleteBin5Fill,
  RiDeleteBin5Line,
} from 'react-icons/ri';
import { addFileToSection } from './Session.util';
import { loadSectionContent } from './Section.util';

interface sectionContent {
  title?: string;
  url: string;
  id: string;
  timestamp: number;
}

interface section {
  id: 'string';
  title: 'string';
  content: sectionContent[];
}

export function Session() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [tab, setTab] = useState('0');
  const [sessionData, setSessionData] = useState<any>(null);
  const [sessionId, setSessionId] = useState<string | undefined>();
  const [sessionInit, setSessionInit] = useState(false);

  const [toggleNewSectionView, setToggleNewSectionView] =
    useState<boolean>(false);
  const [newSectionTitle, setNewSectionTitle] = useState<string>('');

  const [sections, setSections] = useState<any>(undefined);

  const [sessionLayoutWidth, setSessionLayoutWidth] = useState<string>('99%');

  const [sessionLoading, setSessionLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] =
    useState<string>('Cargando sesión');

  const [draggable, setDraggable] = useState<boolean>(false);
  const [removeImagesMode, setRemoveImagesMode] = useState<boolean>(false);

  useEffect(() => {
    setLoadingMessage('Inizializando sala');
    if (id) {
      loadSession(id);
    } else {
      createSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!sessionInit && sessionId) {
      navigate(`/s/${sessionId}`);
      setSessionInit(true);
    }
  });

  const handleFileDrop = async (acceptedFiles: File[], sectionId: string) => {
    setSessionLoading(true);
    setLoadingMessage('Subiendo archivos');

    let filePromises: any = [];

    //let section = getSectionById(sectionId);

    for (let i = 0; i < acceptedFiles.length; i++) {
      let imageFile = acceptedFiles[i];
      let compressedFile = await imageCompression(imageFile, {
        alwaysKeepResolution: true,
      });

      if (imageFile.type === 'image/gif') {
        // If is GIF, we want to use the compressed image as thumbnail and the original will be uploaded to display
        filePromises[i] = uploadImageAsPromise(
          imageFile,
          sectionId,
          compressedFile
        );
      } else {
        // Uploading the file to Storage bucket and Firestore
        filePromises[i] = uploadImageAsPromise(compressedFile, sectionId);
      }
    }

    Promise.allSettled(filePromises)
      .then((results) => {
        setLoadingMessage('Imagenes subidas correctamente');
        results.map(async (file: any) => {
          await addFileToSection(
            sectionId,
            file.value.name,
            file.value.url,
            file.value.path,
            file.value.thumbnail
          );
        });

        loadSession(sessionId as string);
      })
      .catch((err) => {
        console.error(err);
        setLoadingMessage('Error al subir las imagenes');
        setSessionLoading(false);
      });

    loadSections(sectionId);
  };

  function uploadImageAsPromise(
    imageFile: File,
    sectionId: string,
    thumbnail?: File
  ) {
    return new Promise(async (resolve, reject) => {
      const storageRef = ref(
        storage,
        `images/sessions/${sessionId}/${sectionId}/${imageFile.name}`
      );
      const thumbnailRef = ref(
        storage,
        `images/sessions/${sessionId}/${sectionId}/thumbnail-${imageFile.name}`
      );

      // Default file upload
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      // Creaiting thumbnail if required
      let thumbURL: undefined | string = undefined;
      if (thumbnail) {
        const thumbnailTask = uploadBytesResumable(thumbnailRef, thumbnail);

        // Waiting for file to be uploaded
        await thumbnailTask;

        thumbURL = await getDownloadURL(thumbnailTask?.snapshot?.ref);
      }

      uploadTask.on(
        'state_changed',
        (snapshot: any) => {
          const progress = Math.ceil(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          console.error(error);
          reject(error);
          showNotification({
            message: `El intento de subir la imagen ${imageFile.name}`,
            color: 'red',
          });
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask?.snapshot?.ref);
          resolve({
            sectionId,
            name: imageFile.name,
            url: downloadURL,
            path: uploadTask?.snapshot?.ref.fullPath,
            thumbnail: thumbURL,
          });
        }
      );
    });
  }

  const createSession = async () => {
    setLoadingMessage('Creando información de sesión');
    const sessionsRef = collection(db, 'sessions');
    const newSessionDoc = {
      title: 'N/A',
      display: '',
      timestamp: Date.now(),
    };

    let newSession = await addDoc(sessionsRef, newSessionDoc);

    setSessionId(newSession.id);
    setSessionData({ ...newSessionDoc });
    setSessionLoading(false);
  };

  const loadSession = async (id: string) => {
    setSessionLoading(true);
    setLoadingMessage('Cargando información principal de sesión');
    const sessionRef = doc(db, 'sessions', id);
    const sessionDoc = await getDoc(sessionRef);
    const sessionData = sessionDoc.data();

    if (sessionDoc.exists()) {
      setSessionId(id);
      setSessionData({ ...sessionData });
      loadSections(id);
      setSessionLoading(false);
    } else {
      showNotification({
        message: 'La sesión no existe',
        autoClose: 5000,
      });

      navigate('/home');
    }
  };

  const loadSections = async (sessionId?: string) => {
    setSessionLoading(true);
    setLoadingMessage('Cargando secciones de imagenes');
    let sections: any = [];

    if (sessionId) {
      const sectionsQuery = query(
        collection(db, 'sections'),
        where('sessionId', '==', sessionId)
      );

      try {
        const sectionsDoc = await getDocs(sectionsQuery);

        for (let i = 0; i < sectionsDoc.docs.length; i++) {
          let section = sectionsDoc.docs[i].data();
          section.id = sectionsDoc.docs[i].id;
          section.content = await loadSectionContent(section.id);
          sections.push(section);
        }

        setSections([...sections]);
        setSessionLoading(false);
      } catch (e) {
        showNotification({
          message: 'No se pudo obtener la ultima información de la sesión',
          color: 'red',
        });
        setSessionLoading(false);
      }
    }
  };

  const createSection = async (title: string) => {
    setSessionLoading(true);
    setLoadingMessage('Creando sección de imagenes');
    const sectionRef = collection(db, 'sections');
    await addDoc(sectionRef, {
      title,
      sessionId,
    });

    loadSections(sessionId);
  };

  const removeSection = async (id: string) => {
    let auxSections = sections ? [...sections] : [];
    let newSectionsList = auxSections.filter((section) => section.id !== id);
    await deleteDoc(doc(db, 'sections', id));
    setSections([...newSectionsList]);
  };

  const setSectionTitle = async (id: string, title: string) => {
    await updateDoc(doc(db, 'sections', id), {
      title: title,
    })
      .then(() => {
        loadSections(sessionId);
      })
      .catch(() => {
        showNotification({
          message: `No se pudo cambiar el titulo a ${title}`,
        });
      });
  };

  const ViewLayoutElements: ElementMapType[] = [
    {
      title: 'Notas',
      component: (
        <Tabs
          orientation="vertical"
          value={`${tab}`}
          onTabChange={(e) => {
            setTab(e || '0');

            // We need to "shake" the width to get the Gallery component working
            setSessionLayoutWidth('99.9%');
            setTimeout(() => {
              setSessionLayoutWidth('100%');
            }, 100);
          }}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tabs.List
            pr={8}
            pl={8}
            pt={8}
            sx={{
              maxWidth: '150px',
            }}
          >
            {toggleNewSectionView ? (
              <Input
                size="xs"
                value={newSectionTitle}
                placeholder="Sección"
                onChange={(e: any) => setNewSectionTitle(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    createSection(e.target.value);
                    setToggleNewSectionView(false);
                    setNewSectionTitle('');
                  }
                }}
                onBlur={() => {
                  setToggleNewSectionView(false);
                }}
              />
            ) : (
              <Button
                variant="subtle"
                onClick={() => setToggleNewSectionView(true)}
              >
                <ActionIcon size="xl" color="dark">
                  <RiAddBoxFill size={22} />
                </ActionIcon>
              </Button>
            )}

            {sections?.map((section: section, index: number) => (
              <Tabs.Tab
                key={section.id}
                value={`${index}`}
                sx={{
                  padding: '0px',
                  width: '150px',
                  '& .mantine-Tabs-tabLabel': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '150px',
                    alignItems: 'center',
                  },
                }}
                onClick={() => {
                  setRemoveImagesMode(false);
                }}
              >
                <Text size={'xs'} p={8}>
                  {index + 1}-{' '}
                  {section.title.length ? (
                    truncate(section.title, { length: 20 })
                  ) : (
                    <Text italic span>
                      Sin titulo
                    </Text>
                  )}
                </Text>
                <Button
                  size="xs"
                  variant="subtle"
                  pl={2}
                  pr={2}
                  ml={2}
                  sx={{ height: '16px' }}
                  onClick={() => {
                    removeSection(section.id);
                  }}
                >
                  <RiCloseLine />
                </Button>
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {sections?.map((section: section, index: number) => {
            return (
              <Tabs.Panel sx={{ maxWidth: '100%' }} value={`${index}`}>
                <Box
                  sx={{
                    maxHeight: 'calc(98vh - 50px)',
                    overflowY: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: 'white',
                      zIndex: 10,
                    }}
                  >
                    <Box p={8}>
                      <Group sx={{ width: '100%' }}>
                        {sessionId && (
                          <Group spacing={0}>
                            <ConfigureTitle variant="subtle">
                              <RiSettings3Fill />
                            </ConfigureTitle>
                            <Tooltip label="Establecer el titulo en escena">
                              <Button
                                pl={10}
                                pr={10}
                                disabled={sessionLoading}
                                onClick={async () => {
                                  setLoadingMessage(
                                    'Cambiando titulo en escena'
                                  );
                                  await setTitleDisplay(
                                    section.title || 'Sin titulo',
                                    sessionId
                                  );
                                  loadSession(sessionId);
                                }}
                                variant="subtle"
                              >
                                <Group spacing={0} sx={{ minWidth: '28px' }}>
                                  <RiText size={16} />
                                  <RiArrowRightUpLine size={16} />
                                </Group>
                              </Button>
                            </Tooltip>
                          </Group>
                        )}
                        <InputLabel
                          width={250}
                          disabled={sessionLoading}
                          label={section.title || 'Sin titulo'}
                          onChange={(e: string) =>
                            setSectionTitle(section.id, e)
                          }
                        />
                      </Group>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        '.dropzone': {
                          textAlign: 'center',
                          padding: '20px',
                          border: '3px dashed #eeeeee',
                          backgroundColor: '#fafafa',
                          color: '#bdbdbd',
                        },
                        '.dropzone p': {
                          fontFamily:
                            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI emoji',
                        },
                      }}
                    >
                      <Dropzone
                        accept={{
                          'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                        }}
                        onDrop={(e: File[]) => handleFileDrop(e, section.id)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Arrastra los archivos aquí, o da click</p>
                          </div>
                        )}
                      </Dropzone>
                    </Box>
                  </Box>
                  {section?.content?.length && sessionId ? (
                    <Box
                      sx={{
                        display: 'flex',
                        'img:hover': {
                          filter: removeImagesMode ? 'blur(3px)' : '',
                        },
                      }}
                      p={2}
                    >
                      <Box pr={1}>
                        &nbsp;
                        <ActionIcon
                          color={!removeImagesMode ? 'dark' : 'red'}
                          variant="subtle"
                          onClick={() => {
                            setRemoveImagesMode(!removeImagesMode);
                          }}
                        >
                          {removeImagesMode ? (
                            <RiDeleteBin5Fill />
                          ) : (
                            <RiDeleteBin5Line />
                          )}
                        </ActionIcon>
                      </Box>
                      <Box>
                        <Gallery
                          removeMode={removeImagesMode}
                          items={section?.content}
                          sessionId={sessionId}
                          onClick={() => {
                            loadSession(sessionId);
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Tabs.Panel>
            );
          })}
        </Tabs>
      ),
    },
    {
      title: 'Vista en escena',
      component: (
        <>
          {sessionId && (
            <Card m={4}>
              <Box
                sx={{
                  cursor: 'pointer',
                  '&:hover .link-icon': {
                    display: 'block!important',
                  },
                }}
                onClick={() => {
                  handleViewDisplay(sessionId);
                }}
              >
                <Text
                  size={'xs'}
                  weight={500}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  Imagen
                  <span
                    className="link-icon"
                    style={{ marginLeft: '4px', display: 'none' }}
                  >
                    <RiExternalLinkLine />
                  </span>
                </Text>
                <Box
                  sx={{
                    backgroundColor: '#36383C',
                    borderRadius: '10px',
                    width: '100%',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',

                    '& img': {
                      width: 'auto',
                      height: '100%',
                    },
                  }}
                >
                  {sessionId && (
                    <img
                      src={sessionData?.display}
                      alt={sessionData?.display}
                    />
                  )}
                </Box>
              </Box>

              <Box
                mt={8}
                sx={{
                  cursor: 'pointer',
                  '&:hover .link-icon': {
                    display: 'block!important',
                  },
                }}
                onClick={() => {
                  handleViewText(sessionId);
                }}
              >
                <Text
                  size={'xs'}
                  weight={500}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  Titulo en escena
                  <span
                    className="link-icon"
                    style={{ marginLeft: '4px', display: 'none' }}
                  >
                    <RiExternalLinkLine />
                  </span>
                </Text>
                {sessionData?.title ? (
                  <Text className="title-display" size={'sm'}>
                    {sessionData.title}
                  </Text>
                ) : (
                  'N/A'
                )}
              </Box>
            </Card>
          )}
        </>
      ),
    },
    {
      title: 'Información de la sesión',
      component: (
        <Box m={4}>
          <Card>
            <>
              <Text size={'xs'} weight={500}>
                Fecha de creación
              </Text>
              <Text size={'xs'}>
                {UNIXToReadableDate(sessionData?.timestamp || 0)}
              </Text>
            </>
          </Card>
          {sessionLoading && (
            <>
              <Divider />
              <Card sx={{ display: 'flex', alignItems: 'center' }}>
                <Loader size="xs" variant="bars" color={'dark'} />{' '}
                <Text size={'xs'} ml={4}>
                  {loadingMessage}...
                </Text>
              </Card>
            </>
          )}
        </Box>
      ),
    },
  ];

  return (
    <AppShell
      header={
        <SessionHeader
          id={sessionId}
          lock={draggable}
          toggleLock={(lockState) => setDraggable(lockState)}
        />
      }
    >
      {sessionId ? (
        <Box sx={{ width: sessionLayoutWidth, height: '100%' }}>
          <SessionLayout elements={ViewLayoutElements} draggable={draggable} />
        </Box>
      ) : (
        <ViewLoader />
      )}
    </AppShell>
  );
}
