import { Display, DisplayTitle, Home, Login, Session, Open } from '../views';
import PrivateRoute from '../components/PrivateRoute';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context';

export const AppRoutes = () => {
  const { getCurrentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        window.location.href = '/crashed';
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route element={<Login />} path="/login" />

            <Route element={<Display />} path={'/d/:id'} />
            <Route element={<DisplayTitle />} path={'/t/:id'} />

            <Route element={<>Algo salio mal </>} path="/crashed" />

            <Route path="/" element={<PrivateRoute />}>
              <Route element={<Home />} path={'/'} />
              <Route element={<Home />} path={'/home'} />

              <Route path="/s">
                <Route path=":id" element={<Session />} />
                <Route path="" element={<Session />} />
              </Route>

              <Route element={<Open />} path={'/a'} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};
