import { Header } from "@mantine/core";

export function HeaderWithTitle({ title }: { title: string }) {
  return (
    <Header
      height={60}
      pl={"lg"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {title}
    </Header>
  );
}
