import { Box, Loader } from "@mantine/core";

export function ViewLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "calc(100vh - 60px)",
      }}
    >
      <Loader />
    </Box>
  );
}
