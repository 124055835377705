import { AppShell, Container, Grid, Input } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultHeader, Button } from '../../components';
import { db } from '../../config';

export function Open() {
  const [searchId, setSearchId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const searchSessionDocument = async () => {
    if (!searchId) {
      return;
    }
    setIsLoading(true);
    const sessionRef = doc(db, 'sessions', searchId);
    const sessionDoc = await getDoc(sessionRef);

    if (sessionDoc.exists()) {
      navigate(`/s/${sessionDoc.id}`);
    } else {
      setIsLoading(false);
      showNotification({
        message: 'No se encontró la sesión',
        color: 'red',
      });
    }
  };

  return (
    <AppShell header={<DefaultHeader />}>
      <Container>
        <Grid pt={'5rem'}>
          <Grid.Col>
            <Input
              placeholder="ID de la sesión"
              onChange={(e: any) => setSearchId(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  searchSessionDocument();
                }
              }}
            />
          </Grid.Col>

          <Grid.Col>
            <Button
              variant="subtle"
              isLoading={isLoading}
              onClick={() => searchSessionDocument()}
            >
              Buscar
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </AppShell>
  );
}
