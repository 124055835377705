import { Box } from '@mantine/core';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../config';

import { Fade } from 'react-animated-components';

import './DisplayTitle.css';

export function DisplayTitle() {
  const { id } = useParams();

  const [displayTitle, setDisplayedTitle] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [out, setOut] = useState<boolean | null>(null);

  useEffect(() => {
    let unsub = () => {};
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      unsub = onSnapshot(doc(db, 'sessions', id), (doc) => {
        let session = doc.data();
        let displayTitle = localStorage.getItem('displayTitle');

        if (displayTitle !== session?.title) {
          setDisplayedTitle('');

          setTimeout(() => {
            if (session?.title && session) {
              setDisplayedTitle(session?.title);
              localStorage.setItem('displayTitle', session?.title);
              setOut(false);
            }
          }, 2000);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        minHeight: '99vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fade out={!!displayTitle ? false : true}>
        <p
          className="title-display"
          style={{
            fontFamily: `"Bebas Neue", cursive`,
            fontSize: '72pt',
            color: 'white',
          }}
        >
          {displayTitle}
        </p>
      </Fade>
    </Box>
  );
}
