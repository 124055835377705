import { Header } from "@mantine/core";
import { Back } from "./Back";

export function DefaultHeader({ children }: { children?: React.ReactNode }) {
  return (
    <Header height={60} p="xs">
      <Back />

      {children}
    </Header>
  );
}
