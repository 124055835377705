import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";

export const logout = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let callback = await signOut(auth);
      resolve(callback);
    } catch (err) {
      reject(err);
    }
  });
};
