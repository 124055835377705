import { Button, Header, Menu } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Back } from "../../components";
import { copyToClipboard, handleViewText, handleViewDisplay } from "../../util";

export const SessionHeader = ({
  id,
  lock = false,
  toggleLock,
}: {
  id?: string;
  lock: boolean;
  toggleLock: (lock: boolean) => void;
}) => {
  const sessionURL = `${window.location.origin}/s/${id}`;

  return (
    <Header height={60} p="xs">
      <Back to={"/home"} />

      {id && (
        <>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button variant="subtle" color={"dark"}>
                Sessión
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color={"dark"}
                onClick={() => {
                  copyToClipboard(sessionURL);
                  showNotification({
                    message: "Se copio al portapapeles",
                  });
                }}
              >
                Copiar URL de la sesión
              </Menu.Item>
              <Menu.Item
                color={"dark"}
                onClick={() => {
                  handleViewDisplay(id || "");
                }}
              >
                Abrir imagen en escena
              </Menu.Item>
              <Menu.Item
                color={"dark"}
                onClick={() => {
                  handleViewText(id || "");
                }}
              >
                Abrir titulo en escena
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button variant="subtle" color={"dark"}>
                Vista
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                color={"dark"}
                onClick={() => {
                  toggleLock(!lock);
                }}
              >
                {!lock ? "Desbloquear" : "Bloquear"} ventanas
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      )}
    </Header>
  );
};
