import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";

export const login = (email: string, password: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      resolve(user);
    } catch (err) {
      reject(err);
    }
  });
};
