import { Mosaic, MosaicWindow } from 'react-mosaic-component';

import 'react-mosaic-component/react-mosaic-component.css';

export type ElementMapType = {
  title: string;
  component: JSX.Element;
  options?: any[];
};

interface SessionLayoutProps {
  elements: ElementMapType[];
  draggable: boolean;
}

export const SessionLayout = (props: SessionLayoutProps) => {
  const { elements, ...rest } = props;

  return (
    <Mosaic<number>
      renderTile={(id, path) => {
        return (
          <MosaicWindow<number>
            path={path}
            title={props.elements[id - 1].title}
            draggable={rest.draggable}
            toolbarControls={<></>}
          >
            {elements[id - 1].component}
          </MosaicWindow>
        );
      }}
      initialValue={{
        direction: 'row',
        first: 1,
        second: {
          direction: 'column',
          first: 2,
          second: 3,
          splitPercentage: 60,
        },
        splitPercentage: 70,
      }}
    />
  );
};
