// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, ref, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmjGP3PETPsYYUPzvNfRY32pMKLIAzZy4",
  authDomain: "slidenotes-8b91c.firebaseapp.com",
  projectId: "slidenotes-8b91c",
  storageBucket: "slidenotes-8b91c.appspot.com",
  messagingSenderId: "928690822730",
  appId: "1:928690822730:web:db2ac681087249b87a90b3",
  measurementId: "G-JCZ4TW54Z5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const sessionStorageRef = ref(storage);

const db = getFirestore(app);

if (window.location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199);
  connectFirestoreEmulator(db, "localhost", 8090);
}

export { app, analytics, auth, storage, db, sessionStorageRef };
