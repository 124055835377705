import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
//import ImageGallery from "react-image-gallery";
import { Gallery as GridGallery, Image } from 'react-grid-gallery';
import { removeImageFromGallery, setImageDisplay } from '../../util';

import './Gallery.css';

interface GalleryProps {
  items: any[];
  sessionId: string;
  removeMode: boolean;
  onClick: (removedId?: number) => void;
}

interface ImageWithThumbnail extends Image {
  file?: string;
}

export const Gallery = (props: GalleryProps) => {
  const [images, setImages] = useState<ImageWithThumbnail[] | undefined>();
  const [dynamicWidth, setDynamicWidth] = useState<string>('100%');

  useEffect(() => {
    let auxImages: ImageWithThumbnail[] = props.items.map((item: any) => {
      return {
        id: item.id,
        src: item?.thumbnail ?? item.url,
        file: item.url,
        path: item.path,
        thumbnail: item?.thumbnail,
      } as any;
    });

    setImages([...auxImages]);
  }, [props.items]);

  useEffect(() => {
    setTimeout(() => {
      setDynamicWidth('99%');
    }, 1000);
  }, [images]);

  return (
    <Box
      sx={{
        width: dynamicWidth,
      }}
    >
      &nbsp;
      <GridGallery
        enableImageSelection={false}
        images={images || []}
        onClick={async (index) => {
          if (images) {
            if (props.removeMode) {
              let auxItemDelete = images[index] as any;
              await removeImageFromGallery(
                auxItemDelete.path,
                auxItemDelete.id
              );
            } else {
              await setImageDisplay(
                images[index]?.file || images[index]?.src,
                props.sessionId
              );
            }
            props.onClick();
          }
        }}
      />
    </Box>
  );
};
