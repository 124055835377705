import { Alert, AppShell, Box, Grid, Input, Text } from '@mantine/core';
import { RiKeyFill, RiMailFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context';
import { login } from '../../api/login';
import { UserCredential } from 'firebase/auth';
import { Button } from '../../components';

export function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { getCurrentUser, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const response = (await login(
        data.email,
        data.password
      )) as UserCredential;

      setUser(response.user);

      navigate('/home');
    } catch (e: any) {
      setErrorsList([e.message]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUser().then((user) => {
      if (user) {
        let landingUrl = localStorage.getItem('landing-url');

        navigate(landingUrl ? landingUrl : '/home');
        localStorage.removeItem('landing-url');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentUser()]);

  return (
    <AppShell>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '-60px',

          form: {
            width: '100%',
          },
        }}
      >
        <Grid sx={{ maxWidth: '44%' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid.Col>
              <Text size={'lg'}>Slidenotes</Text>
            </Grid.Col>

            {!!errorsList.length && (
              <Grid.Col xs={12}>
                <Alert
                  id={'ErrorAlert'}
                  title="Error"
                  withCloseButton
                  closeButtonLabel="Cerrar errores"
                  color={'red'}
                  onClose={() => {
                    setErrorsList([]);
                  }}
                >
                  Algo salio mal al intentar iniciar sesión
                  {errorsList.map((error) => (
                    <Text mt={4} size="xs">
                      {error}
                    </Text>
                  ))}
                </Alert>
              </Grid.Col>
            )}
            <Grid.Col>
              <Input
                invalid={errors.email ? true : false}
                icon={
                  <>
                    <RiMailFill />
                  </>
                }
                placeholder="Correo"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Correo invalido',
                  },
                })}
              />
            </Grid.Col>
            <Grid.Col>
              <Input
                type="password"
                invalid={errors.password ? true : false}
                icon={<RiKeyFill />}
                placeholder="Contraseña"
                {...register('password', {
                  required: true,
                })}
              />
            </Grid.Col>

            <Grid.Col
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button isLoading={isLoading} type="submit">
                Entrar
              </Button>
            </Grid.Col>
          </form>
        </Grid>
      </Box>
    </AppShell>
  );
}
