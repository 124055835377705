import {
  ButtonProps as MantineButtonProps,
  Loader,
  Button as MantimeButton,
  Box,
} from "@mantine/core";

interface ButtonProps extends MantineButtonProps {
  isLoading?: boolean;
  loaderSize?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = (props: ButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {props?.isLoading ? (
        <Loader size={props?.size || "sm"} color={props?.color || "dark"} />
      ) : (
        <MantimeButton
          type={props?.type || "button"}
          variant={props?.variant || "subtle"}
          color={props?.color || "dark"}
          onClick={props?.onClick}
        >
          {props?.children}
        </MantimeButton>
      )}
    </Box>
  );
};
