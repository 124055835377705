import { doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

export const setTitleDisplay = async (title: string, sessionId: string) => {
  return new Promise((resolve, reject) => {
    try {
      const sessionRef = doc(db, "sessions", sessionId);
      let updatedDoc = updateDoc(sessionRef, {
        title,
      });

      resolve(updatedDoc);
    } catch (err) {
      reject(err);
    }
  });
};
