import { Text, TextInput } from '@mantine/core';
import { ChangeEvent, useEffect, useState } from 'react';

interface InputLabelProps {
  label?: string;
  onChange?: (value: string) => any;
  disabled?: boolean;
  width?: number;
}

export function InputLabel(props: InputLabelProps) {
  const [editable, setEditable] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props?.label || 'Sin titulo');

  useEffect(() => {
    setValue(props?.label || 'Sin titulo');
  }, [props.label]);

  const submitValue = () => {
    props?.onChange?.(value);
    setEditable(false);
    setValue('');
  };

  return (
    <>
      {!editable ? (
        <Text
          sx={{
            userSelect: 'none',
          }}
          onClick={(e: any) => {
            if (e.detail === 2) {
              e.preventDefault();
              setEditable(true);
              setValue(props?.label || 'Sin titulo');
            }
          }}
        >
          {props.label}
        </Text>
      ) : (
        <TextInput
          disabled={props?.disabled || false}
          size={'xs'}
          tabIndex={0}
          sx={{
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
            width: `${props?.width}px` || '100%',
          }}
          value={value}
          onChange={(e: ChangeEvent) => {
            let newValue = (e.target as HTMLInputElement).value;
            if (newValue !== props?.label) {
              setValue((e.target as HTMLInputElement).value || '');
            }
          }}
          onBlur={() => {
            if (value !== props?.label) {
              submitValue();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              submitValue();
            }

            if (e.key === 'Escape') {
              setEditable(false);
            }
          }}
        />
      )}
    </>
  );
}
