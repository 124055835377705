import { User } from 'firebase/auth';
import { createContext } from 'react';

export type UserData = User | null;

interface AuthContextProps {
  user: UserData;
  setUser: (user: User | null) => void;
  getCurrentUser: () => Promise<UserData>;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  setUser: () => {},
  getCurrentUser: async () => {
    return null as UserData;
  },
});
