import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../config';

export const addFileToSection = (
  sectionId: string,
  name: string,
  url: string,
  path: string,
  thumbnail?: string
) => {
  return new Promise<void>(async (resolve, reject) => {
    const sessionsRef = collection(db, 'files');
    await addDoc(sessionsRef, {
      url,
      name,
      sectionId,
      timestamp: Date.now(),
      path,
      thumbnail: thumbnail ?? url,
    });
    resolve();
  });
};
