import { Box } from '@mantine/core';
import { doc, DocumentData, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../config';
import { DEFAULT_IMAGE } from '../../const/DEFAULT_IMAGE';

export function Display() {
  const { id } = useParams();

  const [displayImage, setDisplayedImage] = useState<string>(DEFAULT_IMAGE);

  useEffect(() => {
    let unsub: DocumentData = () => {};
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      unsub = onSnapshot(doc(db, 'sessions', id), (doc) => {
        let session = doc.data();

        if (session) {
          setDisplayedImage(session.display || DEFAULT_IMAGE);
        }
      });
    }
  });

  return (
    <Box
      sx={{
        overflow: 'hidden',
        minHeight: '99vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{ maxHeight: '80vh', height: '100%', width: 'auto' }}
        src={displayImage}
        alt={displayImage}
      />
    </Box>
  );
}
