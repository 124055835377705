import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

import { NotificationsProvider } from "@mantine/notifications";
import { AuthProvider } from "./context";
import { AppRoutes } from "./router/AppRoutes";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <MantineProvider theme={{ primaryColor: "dark" }}>
          <NotificationsProvider>
            <ModalsProvider>
              <AppRoutes />
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
